<template>
  <b-card-code title="微信群列表">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t("message.seachLabel") }}</label>
          <b-form-input
            v-model="searchTerm"
            :placeholder="$t('message.SearchPlaceholder')"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <!-- Slot: Table Column -->

      <!-- Slot: Table Row -->
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'chatroomid'"
          v-b-tooltip.hover
          :title="props.row.chatroomid"
          class="text-nowrap"
        >
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span class="text-nowrap">{{ props.row.nickname }}</span>
        </span>
        <!-- Column: Status -->
        <span
          v-else-if="props.column.field === 'ownername'"
          v-b-tooltip.hover
          :title="props.row.ownerid"
        >
          {{ props.row.ownername }}
        </span>
        <span
          v-else-if="props.column.field === 'atwechat'"
          v-b-tooltip.hover
          :title="props.row.wechatid"
        >
          {{ props.row.atwechat }}
        </span>
        <span v-else-if="props.column.field === 'num'">
          {{ props.row.num }}
        </span>
        <span v-else-if="props.column.field === 'qrcode'">
          <a :href="props.row.qrcode" target="_blank"> 点击查看</a>
        </span>
        <span v-else-if="props.column.field === 'type'">
          <b-badge :variant="statusVariant(props.row.type)">
            {{ props.row.type == 0 ? "否" : "是" }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'rebate'">
          <b-badge :variant="statusVariant(props.row.rebate)">
            {{ props.row.rebate == 0 ? "否" : "是" }}
          </b-badge>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="gotype(props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>{{ props.row.type == 0 ? "设定监听" : "取消监听" }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="gorebate(props.row)"
                :disabled="props.row.rebate == 0 ? false : true"
              >
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>设定产品</span>
              </b-dropdown-item>
              <b-dropdown-item @click="godelete(props.row)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>删除</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> 显示 </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ total }} 条 </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  VBTooltip,
  BAvatar,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BForm,
  BModal,
  VBModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import groupset from "@/views/rebate/GroupSet";
export default {
  components: {
    VBTooltip,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BForm,
    BModal,
    VBModal,
    groupset,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      pages: ["10", "20", "40", "100"],
      pageLength: 10,
      total: 0,
      wechatid: "",
      dir: false,
      columns: [
        {
          label: "群名称",
          field: "chatroomid",
        },

        {
          label: "群主",
          field: "ownername",
        },
        {
          label: "所在微信",
          field: "atwechat",
        },
        {
          label: "人数",
          field: "num",
        },
        {
          label: "群简介",
          field: "notice",
        },
        {
          label: "群二维码",
          field: "qrcode",
        },
        {
          label: "监听群",
          field: "type",
        },
        {
          label: "产品群",
          field: "rebate",
        },
        {
          label: "操作",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  methods: {
    handlePageChange(active) {},
    godelete(chatroom) {
      this.$swal({
        title: "删除该群?",
        text: "删除后微信将会退出群聊?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {});
    },
    gorebate(chatroom) {
      console.log("chatroom.did=", JSON.stringify(chatroom));
      this.$swal({
        title: "设置该群为返利产品群?",
        text: "设置产品群?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "添加",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          useJwt
            .updatebychatroomid({
              chatroomid: chatroom.chatroomid,
              rebate: 1,
            })
            .then((res) => {
              if (res.data.code == 0) {
                useJwt
                  .chatroomadd({
                    cid: chatroom.cid,
                    did: chatroom.did,
                    sendwechat: chatroom.wechatid,
                    chatroomid: chatroom.chatroomid,
                  })
                  .then((res) => {
                    this.$swal({
                      icon: "success",
                      title: "添加成功!",
                      text: "以加入产品群",
                      customClass: {
                        confirmButton: "btn btn-success",
                      },
                    });
                  });
              }
            });
        }
      });
      useJwt
        .queryChatRoomAll({
          cid: this.cid,
          did: this.did,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          console.log("dasda:" + JSON.stringify(res.data.data.rows));
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
    gotype(chatroom) {
      if (chatroom.type == 0) {
        this.$swal({
          title: "设置该群为监听群?",
          text: "监听群里的某用户发的消息同步到其他群！",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "添加",
          cancelButtonText: "取消",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .updatebychatroomid({
                chatroomid: chatroom.chatroomid,
                type: 1,
              })
              .then((res) => {
                this.$swal({
                  icon: "success",
                  title: "以设为监听群!",
                  text: "",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                useJwt
                  .queryChatRoomAll({
                    cid: this.cid,
                    did: this.did,
                    wechatid: this.wechatid,
                    page: 1,
                    rows: this.pageLength,
                    desc: this.desc,
                  })
                  .then((res) => {
                    // pagelength 一页显示多少条

                    this.total = res.data.data.records;
                    this.rows = res.data.data.rows;
                  });
              });
          }
        });
      } else {
        this.$swal({
          title: "取消该群为监听群?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .updatebychatroomid({
                chatroomid: chatroom.chatroomid,
                type: 0,
              })
              .then((res) => {
                this.$swal({
                  icon: "success",
                  title: "取消成功!",
                  text: "以取消该群为监听群",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                useJwt
                  .queryChatRoomAll({
                    cid: this.cid,
                    did: this.did,
                    wechatid: this.wechatid,
                    page: 1,
                    rows: this.pageLength,
                    desc: this.desc,
                  })
                  .then((res) => {
                    // pagelength 一页显示多少条
                    this.total = res.data.data.records;
                    this.rows = res.data.data.rows;
                  });
              });
          }
        });
      }
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: "light-success",
        1: "light-danger",

        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },

    direction() {
      // if (store.state.appConfig.isRTL) {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.dir = true;
      //   return this.dir;
      // }
      // // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.dir = false;
      // return this.dir;
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.wechatid = router.currentRoute.params.wechatid;

    this.cid = user.cid;
    this.did = user.did;
    if (this.wechatid != null) {
      console.log("wechatid", this.wechatid != null);
      useJwt
        .queryChatRoomAll({
          cid: this.cid,
          did: this.did,
          wechatid: this.wechatid,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
        })
        .then((res) => {
          // pagelength 一页显示多少条

          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    } else {
      console.log("wechatid", this.wechatid == null);
      useJwt
        .queryChatRoomAll({
          cid: this.cid,
          did: this.did,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
        })
        .then((res) => {
          // pagelength 一页显示多少条

          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
