<template>
  <b-modal
    id="modal-xl"
    centered
    size="x"
    cancel-variant="outline-secondary"
    title="产品发送设置"
    ok-title="添加"
    cancel-title="关闭"
    @ok="submit1"
  >
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="开启发送"
            label-for="h-first-name"
            label-cols-md="4"
          >
            <b-form-checkbox
              v-model="open"
              class="custom-control-success"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="产品类型" label-for="h-email" label-cols-md="4">
            <b-form-checkbox-group
              v-model="selected"
              :options="options"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              class="demo-inline-spacing"
              @change="aa"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="监听群" label-for="nickname" label-cols-md="4">
            <b-form-select
              v-model="selectmonitorchatroomid"
              :options="chatroomOptions"
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              @input="indexChatroomid"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="群成员" label-for="members" label-cols-md="4">
            <b-form-select
              :options="chatroommembersOptions"
              v-model="selectmonitorwechat"
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              @input="indexChatroomMembers"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <!-- <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="submit1"
          >
            提 交
          </b-button>
        </b-col> -->
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BForm,
  BDropdownItem,
  BDropdown,
  BModal,
  VBModal,
  BFormCheckbox,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BButton,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";
export default {
  props: {
    chatroomset: String,
    required: true,
  },
  components: {
    BDropdownItem,
    BDropdown,
    BFormGroup,
    BFormSelect,
    vSelect,
    BModal,
    BForm,
    VBModal,
    BFormCheckbox,
    BRow,
    BCol,
    BFormInput,
    BButton,
    Ripple,
    BFormCheckboxGroup,
    BCardCode,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  created() {
    if (this.chatroomset != "") {
      this.chatroomarr = JSON.parse(this.chatroomset);
    }

    const user = JSON.parse(localStorage.getItem("userData"));
    useJwt
      .querychatroombytype({
        cid: user.cid,
        did: user.did,
        type: 1,
      })
      .then((res) => {
        const forArr = res.data.data;
        this.chatroomOptions.push({
          name: "不监听",
          item: "",
        });
        forArr.forEach((item, i) => {
          this.chatroomOptions.push({
            name: item.nickname,
            item: item.chatroomid,
          });
          console.log("chatroomset的", this.chatroomOptions);
        });
      });
  },
  methods: {
    aa() {
      let arr = this.selected;
      if (arr.length == 14) {
        this.selected = ["0"];
      }
      for (const key in arr) {
        if (arr[key] == "0") {
          this.selected = ["0"];
        }
      }
    },
    indexChatroomid(chatroomid) {
      this.monitorchatroomid = chatroomid;
      this.chatroommembersOptions = [];
      this.selectmonitorwechat = "";
      useJwt
        .findChatRoomMemberBychatroomid({ chatroomid: chatroomid })
        .then((res) => {
          if (res.data.code == 0) {
            const forArr = res.data.data;

            forArr.forEach((item, i) => {
              this.chatroommembersOptions.push({
                name: item.mwechatnick,
                item: item.mwechatid,
              });
            });
          }
        });
    },
    indexChatroomMembers(wechatid) {
      this.monitorwechat = wechatid;
    },
    submit1() {
      this.typeid = this.selected.join();
      this.$swal({
        title: "确定要修改吗?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          useJwt
            .chatroomtaskupdate({
              open: this.open == true ? 1 : 0,
              type: this.typeid,
              monitorchatroomid: this.monitorchatroomid,
              monitorwechat: this.monitorwechat,
              id: this.chatroomarr.id,
            })
            .then((res) => {
              console.log("res.code", res);
              if (res.status == 200) {
                this.$swal({
                  icon: "success",
                  title: "修改成功!",
                  text: "",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                }).then((res) => {
                  if (res.value) {
                    this.$emit("refreshChangeDataList");
                  }
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "修改失败!",
                  text: "请联系管理员处理",
                  customClass: {
                    confirmButton: "btn btn-error",
                  },
                });
              }
            });
        }
      });
    },
  },

  watch: {
    // chatroomarr: {
    //   handler(newVal, oldVal) {
    //     this.selected = [];
    //     this.chatroommembersOptions = [];
    //     this.selected = this.chatroomarr.type.split(",");
    //     console.log("变动", JSON.stringify(this.selected));
    //     if (this.chatroomarr.open == 0) {
    //       this.open = false;
    //     } else {
    //       this.open = true;
    //     }
    //     this.selectmonitorchatroomid = this.chatroomarr.monitorchatroomid;

    //     this.selectmonitorwechat = this.chatroomarr.monitorwechat;
    //   },
    // },
    chatroomset: {
      handler(newVal, oldVal) {
        console.log("this.chatroomset是", this.chatroomset);
        this.selected = [];
        this.chatroommembersOptions = [];
        this.chatroomarr = JSON.parse(this.chatroomset);
        this.selected = this.chatroomarr.type.split(",");
        this.monitorchatroomid = this.chatroomarr.monitorchatroomid;
        this.monitorwechat = this.chatroomarr.monitorwechat;
        if (this.chatroomarr.open == 0) {
          this.open = false;
        } else {
          this.open = true;
        }
 
        this.selectmonitorchatroomid = this.chatroomarr.monitorchatroomid;

        this.chatroommembersOptions = [];
        useJwt
          .findChatRoomMemberBychatroomid({
            chatroomid: this.chatroomarr.monitorchatroomid,
          })
          .then((res) => {
            if (res.data.code == 0) {
              const forArr = res.data.data;

              forArr.forEach((item, i) => {
                this.chatroommembersOptions.push({
                  name: item.mwechatnick,
                  item: item.mwechatid,
                });
              });
            }
          });
        this.selectmonitorwechat = this.chatroomarr.monitorwechat;
        console.log("this.selectmonitorwechat", this.selectmonitorwechat);
      },
    },
  },
  data() {
    return {
      dir: "ltr",
      selected: [],
      chatroommembersOptions: [],
      chatroomarr: [],
      typeid: "",
      selectmonitorchatroomid: "",
      selectmonitorwechat: "",
      monitorchatroomid: "",
      monitorwechat: "",
      id: "",
      chatroomOptions: [],
      open: 0,
      options: [
        { item: "0", name: "综合" },
        { item: "1", name: "女装" },
        { item: "2", name: "母婴" },
        { item: "3", name: "美妆" },
        { item: "4", name: "居家日用" },
        { item: "5", name: "鞋品" },
        { item: "6", name: "美食" },
        { item: "7", name: "文娱车品" },
        { item: "8", name: "数码家电" },
        { item: "9", name: "男装" },
        { item: "10", name: "内衣" },
        { item: "11", name: "箱包" },
        { item: "12", name: "配饰" },
        { item: "13", name: "户外运动" },
        { item: "14", name: "家装家纺" },
      ],
    };
  },
};
</script>


